<template>
    <div class="sql-witeditor">
        <prism-editor
            v-model="code"
            language="sql"
            :readonly="readonly"
            :emitEvents="true"
            @change="onChange"
            v-hotkey="keymap"
        />

        <div :id="shortcutsTooltipTarget" tabIndex="1" class="sql-witeditor-shortcuts-tooltip-icon">
            <i class="fa fa-info"> </i>
        </div>
        <div :id="actionsTooltipTarget" tabIndex="1" class="sql-witeditor-actions-tooltip-icon">
            <i class="fa fa-gear"> </i>
        </div>
        <b-tooltip :target="shortcutsTooltipTarget" triggers="focus" custom-class="sql-witeditor-tooltip">
            <span class="sql-witeditor-tooltip-title">Keyboard shortcuts</span>
            <b-table-lite :items="shortcuts" :fields="['action', 'shortcut']" small borderless>
                <template v-slot:cell(shortcut)="data">
                    <span v-for="(key, index) in data.item.shortcut" v-bind:key="index" class="shortcut-key-container">
                        <span class="shortcut-key">{{ key }}</span
                        ><i v-if="index < data.item.shortcut.length - 1" class="fa fa-plus"
                    /></span>
                </template>
            </b-table-lite>
        </b-tooltip>
        <b-tooltip
            :target="actionsTooltipTarget"
            triggers="focus"
            placement="bottom"
            custom-class="sql-witeditor-tooltip"
        >
            <b-table-lite :items="shortcuts" :fields="['action']" small borderless>
                <template v-slot:cell(action)="data">
                    <span class="shortcut-key" @click="data.item.method">{{ data.item.action }}</span>
                </template>
            </b-table-lite>
        </b-tooltip>
    </div>
</template>

<script>
import Vue from 'vue'
import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

import 'prismjs'
import 'prismjs/themes/prism.css'
import 'prismjs/components/prism-sql'

import PrismEditor from 'vue-prism-editor'
import 'vue-prism-editor/dist/VuePrismEditor.css'

import sqlFormatter from 'sql-formatter'

import {fromEntries} from '@/shared/fromEntries'

export default {
    components: {PrismEditor},
    props: {
        value: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.code = this.value
        },
    },
    created() {
        this.code = this.value
        this.shortcutsTooltipTarget =
            Math.random()
                .toString(15)
                .substring(2, 10) +
            Math.random()
                .toString(35)
                .substring(2, 10)
        this.actionsTooltipTarget =
            Math.random()
                .toString(15)
                .substring(2, 10) +
            Math.random()
                .toString(35)
                .substring(2, 10)

        const userAgent = navigator.appVersion
        if (userAgent.indexOf('Win') != -1) this.os = 'windows'
        if (userAgent.indexOf('Mac') != -1) this.os = 'osx'
        if (userAgent.indexOf('X11') != -1) this.os = 'unix'
        if (userAgent.indexOf('Linux') != -1) this.os = 'linux'
    },
    data() {
        return {
            code: '',
            os: '',
        }
    },
    computed: {
        keymap() {
            const format =
                this.os === 'osx' ? [['meta+shift+f', this.formatQuery]] : [['ctrl+shift+f', this.formatQuery]]
            return {
                ...fromEntries(format),
            }
        },
        shortcuts() {
            const shortcuts = []
            if (this.os === 'osx') {
                shortcuts.push({action: 'Format query', shortcut: ['cmd', 'shift', 'f'], method: this.formatQuery})
            } else {
                shortcuts.push({action: 'Format query', shortcut: ['ctrl', 'shift', 'f'], method: this.formatQuery})
            }

            return shortcuts
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.code)
        },

        formatQuery(e) {
            e.preventDefault()
            this.code = sqlFormatter.format(this.code)
        },
    },
}
</script>

<style lang="scss">
.sql-witeditor {
    pre.prism-editor__code.language-sql {
        padding: 0.375rem 0.75rem;
        width: 100%;
        min-height: 4rem;
        background: white;
        border: 1px solid #e8e8e8;
        border-radius: 5px;

        font-family: inherit;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
    }
    .sql-witeditor-actions-tooltip-icon,
    .sql-witeditor-shortcuts-tooltip-icon {
        position: absolute;
        right: -20px;
        border: 2px solid #3e515b;
        height: 25px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 25px;
    }

    .sql-witeditor-shortcuts-tooltip-icon {
        top: 5px;
        i {
            font-size: 0.9rem;
            margin: 0 8px;
            color: #3e515b;
        }
    }

    .sql-witeditor-actions-tooltip-icon {
        top: 35px;
        i {
            font-size: 0.9rem;
            margin: 0 4px;
            color: #3e515b;
        }
    }

    .sql-witeditor-shortcuts-tooltip-icon,
    .sql-witeditor-actions-tooltip-icon:focus {
        outline: none;
    }
}

.sql-witeditor-tooltip {
    .sql-witeditor-tooltip-title {
        color: black;
        font-size: 0.85rem;
        font-weight: 600;
    }
    .tooltip-inner {
        background-color: white;
        border: 1px solid #e8e8e8;
        border-radius: 5px;

        .shortcut-key {
            padding: 0.1rem 0.2rem;
            background: #3e515b;
            border-radius: 6px;
            color: white;
            text-transform: uppercase;
            font-weight: 600;
        }

        .fa-plus {
            margin: 0 0.1rem;
        }
    }
}
</style>
